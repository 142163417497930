<template>
	<div class="container">
		<Header  :pageName="pageName" :flag="flag"/>	
		<div class="banner">
			<!-- <div class="banner-content">
				 <span class="banner-title">新闻动态</span>
				 <br/>
				 <span class="banner-sub-title">NEWS</span>
			</div> -->
		</div>
		<div class="main">
			<div class="content">
				<div class="content-l">
					<div class="news-title">{{data.title}}</div>
					<div class="news-time">
						报道时间：{{data.createTime}}
					</div>
					 <el-divider></el-divider>
					 <div class="news-content"  v-html="data.content">
						 
					 </div>
				</div>
				<div class="content-r">
					<div class="content-r-top">
						<div class="top-title">
							<img src="../assets/logo.png" align="absbottom" />
							<span>蓝莓科技————人工智能营销倡导者</span>
						</div>
						<div class="wechat-div">
							<img src="../assets/wechat.jpg" />
							<br/>
							<span class="wechat-text">关注公众号了解更多</span>
						</div>
					</div>
					<div class="content-r-hox">
						<p>
							<span class="recommond-new">推荐新闻</span>
						</p>
						<el-divider></el-divider>
						<div class="content-r-box" v-for="(item,index) in list" >
							<img class="recommond-cover" :src="item.image" />
							<p class="recommond-title" @click="detail(item,index)" >{{item.title}}</p>
							<br/>
							<span class="recommond-intro">{{item.intro}}</span>
						</div>
					</div>
					
				</div>
			</div>
		</div>
		<Footer />	
	</div>
</template>

<script>
	import Header from '@/components/Header.vue'
	import Footer from '@/components/Footer.vue'
	export default {
		metaInfo: {
			title: '蓝莓科技-新闻频道-新闻详情',
			meta: [
			  {
				name: 'keywords',
				content: ''
			  },
			  {
				name: 'description',
				content: '蓝莓科技(www.lanmeihulian.com)新闻频道'
			  }
			]
		},
		name: 'NewsDetail',
		components: {
			Header,
			Footer
		},	
		data(){
			return{
				flag: 'news',
				data:[],
				pageName: "newDetail",
				list: [],
				title: "",
			}
		},
		mounted(){
			this.title="新闻详情"
		},
		created() {
			this.getNews();
			this.getRecommendNews();
		},
		watch: {
		    $route(){
		         if (this.$route.query.id) {
						this.getNews();
		         }
		       }, 
		},
		methods:{
			getNews(){
				this.$http.getNewsDetail(this.$route.query.id).then(res => {
					if(res.data!=null){
						this.data = res.data;
						this.title=this.data.title;
					}
				})
			},
			getRecommendNews(){
				this.$http.getRecommendNewsList({id:this.$route.query.id}).then(res => {
					if(res.data!=null){
						this.list = res.data;
					}
				})
			},
			detail(item,index){
				if(item.link != null && item.link !=''){
					window.open(item.link, '_blank');
				}else{
					this.$router.push({path:'/newsDetail',name:'NewsDetail',query:{id:item.id,t:Date.now()}});
				}
			}
		}
	}		
</script>

<style  scoped lang="less">
// .header-header{
// 	color: #0A72BF;
// 	border-bottom: solid 1px rgba(0,0,0,0.2);
// 	background: #fff;
// 	box-shadow: 0 -1px 0 0 rgb(17 31 44 / 8%);
// 	.header-logo{
// 		cursor: pointer;
// 		span{
// 			color: #0A72BF;
// 		}
// 	}	
// }
.banner{
	 // padding-top: 50px;
	 height: 50px;
	 // background-image: url(https://file.lanmeihulian.com/news-bg.jpg);
	 // background-repeat: no-repeat;
	 // background-size:100% 100%;
	 // .banner-content{
		//  width: 1200px;
		//  margin: auto;
		//  text-align: left;
		//  padding-left: 40px;
		//  .banner-title{
		// 	 display: inline-block;
		// 	 margin-top: 110px;
		// 	 font-size: 42px;
		// 	 font-weight: 600;
		// 	 color: white;
		//  }
		//  .banner-sub-title{
		// 	 display: inline-block;
		// 	 font-size: 42px;
		// 	 font-weight: 600;
		// 	 color: white;
		//  }
	 // }
}
.main{
	width: 1200px;
	margin: 60px auto;
	min-height: 600px;
	height: auto !important;
	.content{
		display: flex;
		display: -webkit-flex;
		flex-direction: row;
		justify-content: center;
		.content-l{
			width: 760px;
			// padding-right: 20px;
			.news-title{
				line-height: 50px;
				font-size: 32px;
				color: #333333;
				letter-spacing: 0;
				font-weight: 600;
			}	
			.news-time{
				height: 20px;
				margin: 24px 0;
				line-height: 20px;
				font-size: 20px;
				color: #999999;
			}
			.news-content{
				margin-top: 80px;
				width: 700px;
				text-align: left;
				// margin: auto;
				font-size: 18px;
				line-height: 35px;
				color: #666666;
				overflow:hidden;
				/deep/ img{
					max-width: 700px !important;
				}
				/deep/ p{
					
					img{
						max-width: 700px !important;
					}
				}
			}
		}
		.content-r{
			width: 380px;
			padding: 16px 24px 24px;
			margin-bottom: 16px;
			.content-r-top{
				height: 450px;
				background-color: #f7f7f7;
				// width: 400px;
				.top-title{
					width: 380px;
					margin: auto;
					text-align: center;
					img{
						width: 80px;
						height: 80px;
					}
					span{
						display: inline-block;
						width: 250px;
						height: 80px;
						margin-top: 50px;
						font-size: 22px;
						line-height: 30px;
						color: #333333;
						text-align: left;
						padding-left: 12px;
					}
				}
				.wechat-div{
					margin-top: 20px;
					width: 380px;
					text-align: center;
					img{
						width: 220px;
						height: 220px;
					}
					.wechat-text{
						display: inline-block;
						margin-top: 26px;
						font-size: 20px;
						color: #333333;
					}
				}
			}
			.content-r-hox{
				margin-top: 84px;
				border: 1px solid #DCDCDC;
				padding: 16px 24px 24px;
				.recommond-new{
					font-size: 24px;
				}
				.article-title{
					line-height: 28px;
					font-size: 20px;
					color: #111f2c;
					letter-spacing: 0;
					font-weight: 500;
					padding-bottom: 16px;
					border-bottom: 1px solid rgba(17,31,44,.12);
					margin-bottom: 16px;
				}
				.content-r-box{
					text-align: center;
					margin: auto;
					width: 296px;
					margin-top: 20px;
					img{
						width: 290px;
						height: 160px;
					}
					.recommond-cover{
						
						margin-top: 16px;
						width: 296px;
						height: 166px;
						border-radius: 4px;
						cursor: pointer;
						object-fit: cover;
					}
					.recommond-title{
						margin-top: 12px;
						color: rgba(17,31,44,.85);
						letter-spacing: 0;
						font-size: 22px;
						color: #333333;
						line-height: 30px;
						font-weight: 400;
						cursor: pointer;
					}
					.recommond-title:hover{
						color: #218BFB;
					}
					.recommond-intro{
						font-size: 18px;
						color: #666666;
						line-height: 25px;
						padding-top: 20px;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-line-clamp: 2;
						-webkit-box-orient: vertical;
						-o-text-overflow: ellipsis;/* 支持 Opera */ 
						 overflow: hidden;
					}
				}
			}
			
		}
	}
}		
</style>
